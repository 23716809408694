import { useSelector } from 'react-redux'
import Container from '../../../UI/Container/Container'
import props from '../../../../redux/props'
import logo from '../../../../assets/image/logo-pastel.webp'
import { getUUID } from '../../../../utility'

const Info = () => {
  const t = useSelector((s) => s[props.TRANSLATION])

  return (
    <div className="h-screen flex items-center relative">
      <Container>
        <img src={logo} className="w-[200px] mx-auto" alt="" />
        <div className="text-2xl md:text-3xl mt-10 text-white text-center">{t.success.headline}</div>
        {t.success.text.map((t: string) => (
          <div className="mt-5 text-md md:text-xl text-white text-center" key={getUUID()}>
            {t}
          </div>
        ))}
      </Container>
    </div>
  )
}

export default Info
