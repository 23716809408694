import separatorLine from '../../../../assets/image/separator-with-logo.svg'
import signature from '../../../../assets/image/signature.svg'
import { useSelector } from 'react-redux'
import Cursor from '../Cursor/Cursor'
import props from '../../../../redux/props'
import Container from '../../../UI/Container/Container'
import { Event } from '../../../../types/Event'
import MediumContainer from '../../../UI/Container/MediumContainer'
import EventOption from '../../../UI/EventOption/EventOption'
import { getUUID } from '../../../../utility'

const Info = () => {
  const t = useSelector((s) => s[props.TRANSLATION])
  const state: Event = useSelector((s) => s[props.STATE])

  return (
    <div className="flex flex-col w-full md:h-full pb-28 md:pb-5 p-5 relative">
      <Cursor />
      <Container>
        <img src={separatorLine} alt="" />
        <div className="text-2xl md:text-3xl mt-3 md:mt-10 text-white text-center">{t.home.sections.info.headline}</div>
        <div className="text-xl md:text-2xl mt-2 md:mt-0 text-white text-center leading-none">{t.home.sections.info.subheadline}</div>
        {t.home.sections.info.text.map((t: string) => (
          <div className="text-sm md:text-sm text-white text-center mt-5" key={getUUID()}>
            {t}
          </div>
        ))}
        <div className="mx-auto mt-5 text-center">
          <img src={signature} alt="" className="w-[75%] sm:w-[300px] inline-block border-b-2 border-white pb-2 mb-2" />
        </div>
        <div className="text-lg text-center text-white font-bold">{t.home.sections.info.name}</div>
        <div className="text-center text-white">{t.home.sections.info.position}</div>
      </Container>
      <MediumContainer>
        <div className="flex flex-col gap-10 md:flex-row justify-center mt-14">
          {!!state && state.options.map((o) => <EventOption key={o.identifier} option={o} />)}
        </div>
      </MediumContainer>
    </div>
  )
}

export default Info
