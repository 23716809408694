import Cursor from '../Cursor/Cursor'
import styled from 'styled-components'
import logo from '../../../../assets/image/logo-pastel.webp'
import folgore from '../../../../assets/image/event_assets/folgore.png'
import formele from '../../../../assets/image/event_assets/formele.png'
import MediumContainer from '../../../UI/Container/MediumContainer'
import { useSelector } from 'react-redux'
import props from '../../../../redux/props'

const Wrapper = styled.div`
  background: rgb(181, 137, 125);
  background: linear-gradient(to top right, rgba(181, 137, 125, 1) 50%, rgba(10, 10, 10, 1) 50%);
`

const Overview = () => {
  const t = useSelector((s) => s[props.TRANSLATION])

  return (
    <Wrapper className="min-h-screen pb-28 md:pb-0 md:h-screen w-full flex flex-col gap-5 md:gap-16 items-center pt-5 md:pt-0 md:justify-center relative bg-no-repeat bg-center">
      <Cursor />
      <img className="h-32" src={logo} alt="" />
      <MediumContainer>
        <a className="flex flex-col md:flex-row gap-5" href="/#form">
          <div className="flex flex-col items-center">
            <img src={folgore} alt="" className="w-[80%] md:w-full" />
            <div className="text-white text-md">{t.home.sections.overview.events.folgore}</div>
          </div>
          <div className="flex flex-col items-center">
            <img src={formele} alt="" className="w-[80%] md:w-full" />
            <div className="text-white text-md">{t.home.sections.overview.events.formele}</div>
          </div>
        </a>
        <a className="text-lg md:text-3xl text-white mt-10 font-bold text-center block" href="/#form">
          {t.home.sections.overview.subline}
        </a>
      </MediumContainer>
    </Wrapper>
  )
}

export default Overview
