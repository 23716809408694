import { crud } from '@think-internet/zeus-frontend-package'

const props = {
  ...crud.Features,

  MEMBER: 'MEMBER',
  EVENT: 'EVENT',
  ATTENDEE: 'ATTENDEE',
  CONTACT: 'CONTACT',

  STATE: 'STATE',
}

export default props
