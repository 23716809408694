import Header from './Header/Header'
import Footer from './Footer/Footer'
import Secure from './Secure/Secure'
import ScrollToTop from './ScrollToTop/ScrollToTop'
import { AuthLevelRequired } from '../../../types/Auth'
import useStateManager from '../../hooks/useStateManager'

type Props = {
  children: React.ReactNode
  className?: string
  authLevelRequired?: AuthLevelRequired
  hideNavigation?: boolean
  hideHeader?: boolean
  hideFooter?: boolean
}

const Layout: React.FC<Props> = ({ children, className, hideNavigation, hideHeader, hideFooter, authLevelRequired = [] }) => {
  useStateManager()

  return (
    <div className={className}>
      <Secure authLevelRequired={authLevelRequired}>
        <ScrollToTop />
        {!hideHeader && <Header hideNavigation={hideNavigation} />}
        <div className={`relative overflow-x-hidden bg-background min-h-screen ${!hideHeader ? 'pt-16' : ''}`}>{children}</div>
        {!hideFooter && <Footer />}
      </Secure>
    </div>
  )
}

export default Layout
