export const translation = {
  projectName: 'Event-Hub',

  header: {
    navigation: {
      default: [
        {
          name: 'Login',
          link: '/login',
        },
      ],
      MEMBER: [
        {
          name: 'Checkliste',
          link: '/member/checklist',
        },
        {
          name: 'Export',
          link: '/member/export',
        },
      ],
      isLoggedIn: {
        name: 'Abmelden',
      },
    },
  },

  home: {
    loadingError: 'Daten konnten nicht geladen werden.',
    sections: {
      overview: {
        events: {
          folgore: 'MASERATI FOLGORE PREMIERE',
          formele: 'FORMEL E - WATCHPARTY',
        },
        subline: 'CHOOSE YOUR MASERATI EVENTS',
      },
      info: {
        headline: 'MASERATI FOLGORE',
        subheadline: 'Elektrizität in ihrer schönsten Form',
        name: 'Andreas Kaulfuß',
        position: 'General Manager',
        text: [
          'Für Maserati hat das elektrische Zeitalter längst begonnen. Es ist geprägt durch unsere technisch anspruchsvollen Folgore Modelle und durch das Engagement von Maserati MSG Racing in der Formel-E-Weltmeisterschaft.',
          'Wir laden Sie ein, im Vorfeld der Formel-E-Rennen in Berlin in die Welt von Folgore (italienisch für Blitz) einzutauchen: Wir präsentieren Ihnen dort die Marken-Ikone GranTurismo Folgore sowie den brandneuen Grecale Folgore. Zudem stehen hochrangige Vertreter von Maserati und Teammitglieder von Maserati MSG Racing für Expertengespräche zur Verfügung.',
          'Wenige Tage später öffnen wir die Türen unserer Niederlassung zur gemeinsamen Formel E - WatchParty.',
        ],
      },
      form: {
        headline: 'Anmeldung',
        limitReached: {
          first: 'Es tut uns leid.',
          second: 'Aufgrund der großen Anfrage sind bereits alle Plätze belegt.',
          third: 'Kontaktieren Sie uns bitte telefonisch unter +49 (0)30 77 90 790-70 da möglicherweise weitere Plätze frei werden könnten.',
        },
        firstname: 'Vorname',
        lastname: 'Nachname',
        mail: 'E-Mail Adresse',
        withExtraPerson: 'Ich komme mit Begleitung',
        terms: {
          prefix: 'Ich habe die ',
          value: 'Datenschutzrichtlinien',
          suffix: ' gelesen und akzeptiert.',
        },
        chooseHeadline: 'choose one or both maserati events',
        cta: 'Einladung bestätigen',
        submitError: 'Ein Fehler ist aufgetreten. Evtl. sind Sie bereits registriert.',
        eventOptionError: 'Bitte wählen Sie mindestens ein Event aus.',
      },
    },
  },

  success: {
    headline: 'IHRE ANMELDUNG WAR ERFOLGREICH!',
    text: [
      'Vielen Dank für Ihre Anmeldung zu unserem Event. Ihre Bestätigung wurde bereits auf der Gästeliste vermerkt.',
      'Wir freuen uns schon darauf, Sie bei unserem Event begrüßen zu dürfen.',
      'Sie erhalten in Kürze eine E-Mail mit allen Informationen zu Ihrem Eventbesuch.',
    ],
  },

  member: {
    checklist: {
      title: 'Checkliste',
      error: 'Checkliste konnte nicht geladen werden.',
      errorToggle: 'Teilnahme konnte nicht geändert werden.',
      attendeeColumn: 'Anwesend',
      tooltip: {
        attendedEvent: 'Teilnahme bestätigen',
        extraAttendedEvent: 'Teilnahme Begleitung bestätigen',
      },
    },
    export: {
      headline: 'Export',
      total: {
        title: 'Alle Anmeldungen',
        filename: (optionName: string) => `anmeldungen-${optionName}.csv`,
      },
      attended: {
        title: 'Teilnehmer',
        filename: (optionName: string) => `teilnehmer-${optionName}.csv`,
      },
      notAttended: {
        title: 'Nicht teilgenommen',
        filename: (optionName: string) => `nicht-teilgenommen-${optionName}.csv`,
      },
      salesForce: {
        title: 'Salesforce',
        filename: (optionName: string) => `SalesForce-${optionName}.csv`,
      },
    },
  },

  attendee: {
    attributes: {
      firstname: 'Vorname',
      lastname: 'Nachname',
      mail: 'E-Mail Adresse',
      withExtraPerson: 'Begleitung',
      firstnameExtra: 'Vorname (Begleitung)',
      lastnameExtra: 'Nachname (Begleitung)',
      attendedEvent: 'Teilnahme',
      extraAttendedEvent: 'Teilnahme (Begleitung)',
    },
  },

  login: {
    headline: 'Login',
    cta: 'Anmelden',
    error: 'Anmeldung fehlgeschlagen. Falsche E-Mail oder Passwort.',
  },

  footer: {
    links: [
      { name: 'Impressum', link: 'https://guenther-gruppe.de/maserati/impressum/' },
      { name: 'Datenschutz', link: 'https://guenther-gruppe.de/maserati/datenschutz/' },
    ],
    hint: 'Mit der Teilnahme an der Veranstaltung erteilt der Gast dem Veranstalter das Recht, Bildaufnahmen des Gastes zu senden oder senden zu lassen und davon Aufzeichnungen herzustellen sowie diese selbst oder durch Dritte auszustrahlen und in den Bereichen der audiovisuellen Medien zu nutzen. Wie Ihnen sicherlich bekannt ist, gelten für Einladungen an Amtsträger und Unternehmensrepräsentanten besondere rechtliche Anforderungen. Bei Journalisten gelten vergleichbare berufsethische Bestimmungen. Diese Vorgaben wurden bei der Planung der MASERATI FOLGORE PREMIERE am 08.05.2024 sowie FORMEL E - WATCH PARTY am 11.05.2024 berücksichtigt. Ergänzend bitten wir Sie zu prüfen, ob Ihre Teilnahme nach den geltenden rechtlichen Vorschriften, Vorgaben aus Pressekodizes und/oder nach den internen Regelungen Ihres Hauses möglich ist. Hiervon gehen wir im Falle Ihrer Zusage aus. Die Pauschalsteuer in Deutschland auf etwaige geldwerte Vorteile wird von Autohaus Günther GmbH übernommen.',
    dataProtection: 'Datenschutz',
    imprint: 'Impressum',
    copyright: `Copyright © ${new Date().getFullYear()}`,
    creator: 'Blackflagship Markenagentur GmbH',
  },

  generic: {
    level: {
      title: 'Rolle',
      ADMIN: 'Administrator',
      MEMBER: 'Mitarbeiter',
    },
    company: {
      name: '',
      street: '',
      city: '',
      mail: '',
    },
    timestampPrefix: 'Erstellt am: ',
    phonePrefix: 'Telefon: ',
    mailPrefix: 'E-Mail: ',
    websitePrefix: 'Webseite: ',
    employeeSuffix: ' Mitarbeiter',
    salutation: 'Anrede',
    name: 'Name',
    firstname: 'Vorname',
    lastname: 'Nachname',
    birthday: 'Geburtsdatum',
    street: 'Straße, Hausnummer',
    companyName: 'Firmenname',
    club: 'Verein (optional)',
    city: 'PLZ, Ort',
    mail: 'E-Mail-Adresse',
    identifier: 'E-Mail-Adresse',
    message: 'Ihre Anfrage...',
    phone: 'Telefonnummer',
    password: 'Passwort',
    passwordRepeat: 'Passwort wiederholen',
    createdOn: 'Erstellt am',
    confirm: 'Bestätigen',
    cancel: 'Abbrechen',
    close: 'Schließen',
    send: 'Senden',
    error: 'Ein unerwarteter Fehler ist aufgetreten. Bitte versuche es später erneut.',
    acceptAGB: 'Ich akzeptiere die AGB',
    percent: ' %',
    kilometerSuffix: ' km',
    upload: 'Hochladen',
    login: 'Anmelden',
    logout: 'Abmelden',
    export: 'Export',
    import: 'Import',
  },

  UI: {
    singleList: {
      search: 'Suche',
      resultAmountSuffix: ' Ergebnisse',
      noData: 'Keine Daten vorhanden',
    },
    multiList: {
      search: 'Suche',
      resultAmountSuffix: ' Ergebnisse',
      noData: 'Keine Daten vorhanden',
      cta: 'Ausführen',
      cancelCTA: 'Abbrechen',
      option: {
        EXPORT: 'Export',
        PROCESS: 'Verarbeiten',
        STOP: 'Stoppen',
      },
      export: {
        title: 'Export',
        identifier: 'Kennung',
      },
      error: 'Aktion konnte nicht ausgeführt werden',
    },
    csvImport: {
      title: 'Import',
      startCTA: 'Import starten',
      loading: 'Daten werden imporiert...',
      error: 'Der Import ist fehlgeschlagen.',
    },
    dropdown: {
      emptySelection: 'Bitte wählen',
    },
    selectableTagList: {
      showMore: 'Mehr anzeigen',
      showLess: 'Weniger',
    },
    textarea: {
      max: {
        prefix: 'maximal ',
        suffix: ' Zeichen',
      },
    },
    fileSelect: {
      fileToLargePrefix: 'Die ausgewählte Datei ist zu groß: ',
      allowedFileTypes: 'Erlaubte Dateitypen: ',
      allowedFileSize: 'Max. erlaubte Dateigröße: ',
    },
    dnd: {
      text: 'Bewerbung anhängen',
      hint: '(Erlaubt ist eine PDF-Datei bis zu 25 MB)',
      selectedPrefix: 'Gewählte PDF: ',
      error: {
        size: 'Deine Ausgewählte PDF ist zu groß. Maximal erlaubte Dateigröße: 25 MB',
        type: 'Bitte lade nur PDF-Dateien hoch.',
      },
    },
  },

  resetPassword: {
    passwordsDontMatch: 'Passwörter stimmen nicht überein',
    passwordReset: 'Passwort wurde erfolgreich geändert',
    save: 'Ändern',
    password: 'Passwort',
    passwordRepeat: 'Passwort wiederholen',
    title: 'Passwort ändern',
  },
}

export const langKey = 'DE'
