import { useSelector } from 'react-redux'
import MultiList from '../../UI/MultiList/MultiList'
import props from '../../../redux/props'
import useToast, { Type } from '../../hooks/useToast'
import { useState } from 'react'
import { Attendee } from '../../../types/Attendee'
import { crud } from '@think-internet/zeus-frontend-package'
import AttendedToggles from './AttendedToggles'
import { EventOption } from '../../../types/EventOption'

type Props = {
  option: EventOption
}

const SingleList: React.FC<Props> = ({ option }) => {
  const t = useSelector((s) => s[props.TRANSLATION])
  const [attendees, setAttendees] = useState<Attendee[]>()
  const attendeeFeature = crud.use(props.ATTENDEE)
  const toast = useToast()

  const filter = (search: string) => (item: Attendee) => {
    return (
      item.firstname.toLowerCase().includes(search.toLowerCase()) ||
      item.lastname.toLowerCase().includes(search.toLowerCase()) ||
      item.mail.toLowerCase().includes(search.toLowerCase())
    )
  }

  const pull = async (attendee?: Attendee) => {
    if (attendee) {
      setAttendees(attendees?.map((a) => (a.uuid === attendee.uuid ? attendee : a)))
    } else {
      const attendees = await attendeeFeature.list({ eventOptionUUID: option.uuid })
      if (Array.isArray(attendees)) {
        setAttendees(attendees)
      } else {
        toast(Type.ERROR, t.member.checklist.error)
      }
    }
  }

  const propParser = {
    firstname: (item: Attendee) => item.firstname,
    lastname: (item: Attendee) => item.lastname,
    mail: (item: Attendee) => item.mail,
    withExtraPerson: (item: Attendee) => (!!item.withExtraPerson ? 'Ja' : 'Nein'),
    firstnameExtra: (item: Attendee) => item.firstnameExtra || '-',
    lastnameExtra: (item: Attendee) => item.lastnameExtra || '-',
  }

  return (
    <MultiList
      title={`${t.member.checklist.title} - ${option.name}`}
      pull={pull}
      filter={filter}
      list={attendees}
      propParser={propParser}
      translationObject={t.attendee.attributes}
      Action={AttendedToggles}
      ActionColumn={<div className="font-bold">{t.member.checklist.attendeeColumn}</div>}
    />
  )
}

export default SingleList
