import { useSelector } from 'react-redux'
import { functional } from '@think-internet/zeus-frontend-package'
import props from '../../redux/props'
import Routes from '../../redux/routes'
import { Event } from '../../types/Event'
import { downloadFile } from '../../utility'

const useGetCalendarEvent = () => {
  const getCalendarEvent = functional.use(Routes.GET_CALENDAR_EVENT)
  const state: Event = useSelector((s) => s[props.STATE])

  const start = (eventOptionUUID: string) => async () => {
    const option = state.options.find((o) => o.uuid === eventOptionUUID)
    if (!option) return

    const data = await getCalendarEvent({ eventOptionUUID }, null, null, null, null, 'text/plain')
    if (!!data) {
      downloadFile(`${option.name}.ics`, data)
    }
  }

  return start
}

export default useGetCalendarEvent
