import Info from './Sections/Info/Info'
import Hero from './Sections/Hero/Hero'
import Overview from './Sections/Overview/Overview'
import Footer from './Sections/Footer/Footer'
import Form from './Sections/Form/Form'
import Layout from '../UI/Layout/Layout'

const Home = () => {
  const sections = [<Hero />, <Overview />, <Info />, <Form />, <Footer />]

  return (
    <Layout authLevelRequired={false} hideHeader hideFooter>
      <div className="md:snap-y md:snap-mandatory md:snap-always bg-black h-screen w-screen overflow-y-scroll no-scrollbar overflow-x-hidden scroll-smooth">
        {sections.map((section, index) => (
          <div key={index} className={`md:snap-start w-full min-h-screen md:h-screen`}>
            {section}
          </div>
        ))}
      </div>
    </Layout>
  )
}

export default Home
