import props from '../../../../redux/props'
import { useSelector } from 'react-redux'
import LargeContainer from '../../Container/LargeContainer'
import logo from '../../../../assets/image/logo.svg'

const Footer = () => {
  const t = useSelector((s) => s[props.TRANSLATION])

  return (
    <div className="bg-black py-10">
      <LargeContainer>
        <div className="flex flex-col gap-10 lg:gap-0 lg:flex-row justify-between lg:items-center">
          <div className="flex flex-col gap-10 lg:gap-4 lg:w-1/3">
            <div className="flex flex-row justify-center lg:justify-start items-center gap-3">
              <img src={logo} alt="" className="h-12 lg:h-9" />
            </div>
          </div>
          <div className="flex flex-col gap-10 lg:w-1/3">
            <div className="flex flex-col lg:flex-row justify-between">
              {t.footer.links.map((link: { name: string; link: string }, i: number) => (
                <a href={link.link} className="text-sm text-white" key={i}>
                  {link.name}
                </a>
              ))}
            </div>
          </div>
          <div className="lg:w-1/3 flex lg:justify-end">
            <div className="flex flex-col gap-4"></div>
          </div>
        </div>
      </LargeContainer>
    </div>
  )
}

export default Footer
