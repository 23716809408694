import React from 'react'
import styled from 'styled-components'

export const HiddenCheckbox = styled.input.attrs({ type: 'checkbox' })`
  border: 0;
  clip: rect(0 0 0 0);
  height: 1px;
  margin: -1px;
  overflow: hidden;
  padding: 0;
  position: absolute;
  white-space: nowrap;
  width: 1px;
`

type Props = {
  className?: string
  checked: boolean
  label?: string
  onChange: (checked: boolean) => void
  light?: boolean
  required?: boolean
}

const Checkbox: React.FC<Props> = ({ className, checked, label, onChange, light, required = false, ...props }) => (
  <div className={`flex items-center gap-1 w-full ${className}`}>
    <HiddenCheckbox required={required} checked={checked} onChange={() => {}} {...props} />
    <div
      className={`w-5 aspect-square shrink-0 rounded-lg border-2 ${light ? 'border-white' : 'border-black'} cursor-pointer`}
      onClick={() => onChange(!checked)}
    >
      {checked && (
        <svg className={`${light ? 'stroke-white' : 'stroke-black'} fill-none w-4 stroke-[3px]`} viewBox="0 0 24 24">
          <polyline points="20 6 9 17 4 12" />
        </svg>
      )}
    </div>
    {label && (
      <div onClick={() => onChange(!checked)} className={`cursor-pointer ${light ? 'text-white' : 'text-black'}`}>
        {label}
      </div>
    )}
  </div>
)

export default Checkbox
