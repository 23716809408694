enum Routes {
  LOAD_STATE = 'LOAD_STATE',

  ADD_ATTENDEE = 'ADD_ATTENDEE',
  ATTENDEE_EXPORT = 'ATTENDEE_EXPORT',
  SALES_FORCE_EXPORT = 'SALES_FORCE_EXPORT',
  UPDATE_ATTENDEE_STATUS = 'UPDATE_ATTENDEE_STATUS',
  SEND_INVITATION = 'SEND_INVITATION',
  CSV_IMPORT = 'CSV_IMPORT',
  GET_CALENDAR_EVENT = 'GET_CALENDAR_EVENT',
}

export default Routes
