import { ArrowPathIcon } from '@heroicons/react/24/solid'
import { useState } from 'react'
import styled from 'styled-components'

const Icon = styled(ArrowPathIcon)<{ animated: boolean }>`
  animation: ${(props) => (props.animated ? 'spin 0.75s linear infinite' : '')};
  @keyframes spin {
    100% {
      transform: rotate(360deg);
    }
  }
`

type Props = { pull: () => void }

const Refresh: React.FC<Props> = ({ pull }) => {
  const [isLoading, setIsLoading] = useState<boolean>(false)
  const click = async () => {
    setIsLoading(true)
    await pull()
    await new Promise((resolve) => setTimeout(resolve, 750))
    setIsLoading(false)
  }

  return <Icon animated={isLoading} className="text-blue w-5 cursor-pointer" onClick={click} />
}

export default Refresh
