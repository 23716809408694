import styled from 'styled-components'

// input:checked ~ .checkmark:after {
//     display: block;
//   }

export const Input = styled.input.attrs<{ type?: string }>((props) => ({
  type: props.type || 'text',
}))`
  padding: 3px 20px;
  border-radius: 50px;
  display: block;
  width: 50%;
  margin-left: auto;
  margin-right: auto;
  margin-top: 15px;
  background-color: transparent;
  border: 2px solid ${({ theme }) => theme.color.white};
  font-size: 25px;
  color: ${({ theme }) => theme.color.white};
  outline: none;
  &::placeholder {
    /* Chrome, Firefox, Opera, Safari 10.1+ */
    color: ${({ theme }) => theme.color.white};
    opacity: 1; /* Firefox */
    font-family: 'thin';
  }

  &:-ms-input-placeholder {
    /* Internet Explorer 10-11 */
    color: ${({ theme }) => theme.color.white};
  }

  &::-ms-input-placeholder {
    /* Microsoft Edge */
    color: ${({ theme }) => theme.color.white};
  }

  @media (max-width: ${({ theme }) => theme.breakpoint.md}) {
    width: 100%;
    font-size: 20px;
  }
`

export const WithExtraPersonLabel = styled.label`
  width: 50%;
  border-radius: 30px;
  background-color: transparent;
  background-size: 100% 100%;
  text-align: left;
  line-height: 47.5px;
  border: 2px solid ${({ theme }) => theme.color.white};
  margin-top: 15px;
  margin-left: auto;
  margin-right: auto;
  font-size: 20px;
  display: block;
  .checkmark {
    position: absolute;
    height: 47.5px;
    width: 47.5px;
    background-color: transparent;
    border-radius: 50px;
    margin-left: -1px;
    border: 10px solid ${({ theme }) => theme.color.white};
    &:after {
      content: '';
      position: absolute;
      display: none;
      left: 9px;
      top: 5px;
      width: 10px;
      height: 14.5px;
      border: solid ${({ theme }) => theme.color.white};
      border-width: 0 3px 3px 0;
      -webkit-transform: rotate(45deg);
      -ms-transform: rotate(45deg);
      transform: rotate(45deg);
    }
  }
  .checkmark-text {
    margin-left: 65px;
    text-transform: uppercase;
    color: ${({ theme }) => theme.color.white};
  }

  input:checked ~ .checkmark:after {
    display: block;
  }

  @media (max-width: ${({ theme }) => theme.breakpoint.md}) {
    width: 100%;
    line-height: 40px;
    font-size: 14px;
    .checkmark {
      height: 40px;
      width: 40px;
      &:after {
        left: 6px;
        top: 3px;
        width: 8px;
        height: 12px;
        border: solid ${({ theme }) => theme.color.white};
        border-width: 0 3px 3px 0;
        -webkit-transform: rotate(45deg);
        -ms-transform: rotate(45deg);
        transform: rotate(45deg);
      }
    }
  }
`

export const ReadTheDisclaimer = styled.input`
  display: none;
`

export const ReadTheDisclaimerLabel = styled.label`
  display: block;
  text-align: left;
  margin-top: 30px;
  margin-bottom: 20px;
  position: relative;
  color: ${({ theme }) => theme.color.white};
  margin-left: auto;
  margin-right: auto;
  width: 50%;

  .checkmark {
    position: absolute;
    height: 25px;
    width: 25px;
    background-color: ${({ theme }) => theme.color.white};
    border-radius: 15px;
    &:after {
      content: '';
      position: absolute;
      display: none;
      left: 9px;
      top: 5px;
      width: 7.5px;
      height: 12px;
      border: solid rgb(77, 77, 77);
      border-width: 0 3px 3px 0;
      -webkit-transform: rotate(45deg);
      -ms-transform: rotate(45deg);
      transform: rotate(45deg);
    }
  }

  input:checked ~ .checkmark:after {
    display: block;
  }

  .checkmark-text {
    margin-left: 35px;
  }

  @media (max-width: ${({ theme }) => theme.breakpoint.md}) {
    width: 100%;
    font-size: 14px;
    margin-top: 20px;
  }
`

export const Checkmark = styled.span`
  position: absolute;
  height: 25px;
  width: 25px;
  background-color: ${({ theme }) => theme.color.white};
  border-radius: 15px;

  &:after {
    content: '';
    position: absolute;
    display: none;
    left: 9px;
    top: 5px;
    width: 7.5px;
    height: 12px;
    border: solid rgb(77, 77, 77);
    border-width: 0 3px 3px 0;
    -webkit-transform: rotate(45deg);
    -ms-transform: rotate(45deg);
    transform: rotate(45deg);
  }
`
