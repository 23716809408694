import { Navigate, Route, Routes } from 'react-router-dom'
import Layout from '../UI/Layout/Layout'
import { Level } from '../../types/Auth'
import Checklist from './Checklist/Checklist'
import Export from './Export/Export'

const Member = () => {
  return (
    <Layout authLevelRequired={[Level.MEMBER]} hideFooter>
      <Routes>
        <Route path={'checklist'} element={<Checklist />} />
        <Route path={'export'} element={<Export />} />
        <Route path={'*'} element={<Navigate replace to="/member/checklist" />} />
      </Routes>
    </Layout>
  )
}

export default Member
