import { BrowserRouter as Router, Route, Routes, Navigate } from 'react-router-dom'
import { ThemeProvider } from 'styled-components'
import theme from './theme'
import './Global.Styled'
import Home from './components/Home/Home'
import Success from './components/Success/Success'
import Login from './components/Login/Login'
import Member from './components/Member/Member'

function App() {
  return (
    <div className="App">
      <ThemeProvider theme={theme}>
        <Router>
          <Routes>
            {/* public */}
            <Route index element={<Home />} />
            <Route path={'success'} element={<Success />} />

            {/* login */}
            <Route path={'login'} element={<Login />} />

            {/* member */}
            <Route path={'member/*'} element={<Member />} />

            {/* fallback routing */}
            <Route path={'*'} element={<Navigate replace to="/" />} />
          </Routes>
        </Router>
      </ThemeProvider>
    </div>
  )
}

export default App
