interface IButton {
  text?: string | React.ReactNode
  type?: string
  href?: string
  onClick?: () => void
  inverted?: boolean
  className?: string
  disabled?: boolean
  light?: boolean
}

enum Type {
  DEFAULT = 'button',
  SUBMIT = 'submit',
}

const Button: React.FC<IButton> = ({
  text,
  onClick,
  href,
  inverted = false,
  type = Type.DEFAULT,
  className = '',
  disabled = false,
  light = false,
}) => {
  const getClasses = () => {
    let classes = 'border py-1 px-3 sm:px-5 md:px-10 font-light rounded-sm w-fit block transition-all'
    if (inverted) {
      classes += ' border-blue bg-transparent text-blue hover:bg-blue hover:text-white'
    } else if (light) {
      classes += ' border-white bg-white text-blue hover:bg-blue hover:text-white'
    } else {
      classes += ' border-blue bg-blue text-white hover:bg-transparent hover:text-blue'
    }
    return classes
  }

  if (!!href) {
    return (
      <a className={`${getClasses()} ${className}`} href={href}>
        {text}
      </a>
    )
  }
  return (
    <button
      className={`${getClasses()} ${className}`}
      type={type === Type.SUBMIT ? Type.SUBMIT : Type.DEFAULT}
      onClick={type !== Type.SUBMIT ? onClick : null}
      disabled={disabled}
    >
      {text}
    </button>
  )
}

export default Button
