import { EventOption as EventOptionType } from '../../../types/EventOption'
import useGetCalendarEvent from '../../hooks/useGetCalendarEvent'
import { getPrettyDateString, getPrettyTimeString } from '../../../utility'
import locFolgore from '../../../assets/image/event_assets/loc-folgore.svg'
import calFolgore from '../../../assets/image/event_assets/cal-folgore.svg'
import locFormele from '../../../assets/image/event_assets/loc-formele.svg'
import calFormele from '../../../assets/image/event_assets/cal-formele.svg'

type Props = {
  option: EventOptionType
}

const EventOption: React.FC<Props> = ({ option }) => {
  const getCalendarEvent = useGetCalendarEvent()

  const bg = option.identifier === 'folgore' ? 'bg-pastel' : 'bg-lightblue'
  const text = option.identifier === 'folgore' ? 'text-pastel' : 'text-lightblue'

  return (
    <div key={option.uuid} className="flex-1 flex flex-col gap-3">
      <div className={`text-white md:text-lg font-bold uppercase ${bg} rounded-lg p-3 w-full text-center`}>{option.name}</div>
      <div className=" cursor-pointer flex flex-row gap-3 items-center" onClick={getCalendarEvent(option.uuid)}>
        <div className="w-16 shrink-0 aspect-square flex items-center justify-center">
          <img src={option.identifier === 'folgore' ? calFolgore : calFormele} alt="" className="h-8 md:h-14" />
        </div>
        <div>
          <div className={`${text}`}>{getPrettyDateString(option.dateTime as unknown as string)}</div>
          <div className={`${text}`}>{getPrettyTimeString(option.dateTime as unknown as string)}</div>
        </div>
      </div>
      <a className=" cursor-pointer flex flex-row gap-3 items-center" target="_blank" href={option.locationLink} rel="noreferrer">
        <div className="w-16 shrink-0 aspect-square flex items-center justify-center">
          <img src={option.identifier === 'folgore' ? locFolgore : locFormele} alt="" className="h-8 md:h-14" />
        </div>
        <div>
          <div className={`${text}`}>{option.location}</div>
        </div>
      </a>
    </div>
  )
}

export default EventOption
