import { Attendee } from '../../../types/Attendee'
import Checkbox from '../../UI/Checkbox/Checkbox'
import Tooltip from '../../UI/Tooltip/Tooltip'
import { useSelector } from 'react-redux'
import props from '../../../redux/props'
import { crud } from '@think-internet/zeus-frontend-package'
import useToast, { Type } from '../../hooks/useToast'

type Props = {
  initData: Attendee
  successCallback: (attendee: Attendee) => void
}
const AttendedToggles: React.FC<Props> = ({ initData, successCallback }) => {
  const t = useSelector((s) => s[props.TRANSLATION])
  const attendeeFeature = crud.use(props.ATTENDEE)
  const toast = useToast()

  const save = async (data: Attendee) => {
    const attendee = await attendeeFeature.update(data)
    if (attendee) {
      successCallback(attendee)
    } else {
      toast(Type.ERROR, t.member.checklist.errorToggle)
    }
  }

  const toggleMain = () => save({ ...initData, attendedEvent: !initData.attendedEvent })
  const toggleExtra = () => save({ ...initData, extraAttendedEvent: !initData.extraAttendedEvent })

  return (
    <div className="flex gap-3 flex-row">
      <Tooltip text={t.member.checklist.tooltip.attendedEvent}>
        <Checkbox checked={initData.attendedEvent} onChange={toggleMain} />
      </Tooltip>
      <Tooltip text={t.member.checklist.tooltip.extraAttendedEvent}>
        <Checkbox checked={initData.extraAttendedEvent} onChange={toggleExtra} />
      </Tooltip>
    </div>
  )
}

export default AttendedToggles
