import { useSelector } from 'react-redux'
import props from '../../../redux/props'
import SmallContainer from '../../UI/Container/SmallContainer'
import Headline from '../../UI/Headline/Headline'
import Routes from '../../../redux/routes'
import CSVExport from '../../UI/CSVExport/CSVExport'
import { State } from '../../../types/State'

const Export = () => {
  const t = useSelector((s) => s[props.TRANSLATION])
  const state: State = useSelector((s) => s[props.STATE])

  if (!state) return null
  return (
    <div className="my-10">
      <SmallContainer>
        <Headline text={t.member.export.headline} />
        <div className="flex flex-col gap-10 mt-10">
          {state.options.map((option) => {
            return (
              <div>
                <div className="font-bold">{option.name}</div>
                <div className="flex justify-between items-center mt-3">
                  <div>{t.member.export.total.title}</div>
                  <CSVExport
                    route={Routes.ATTENDEE_EXPORT}
                    filename={t.member.export.total.filename(option.name)}
                    payload={{ payload: { eventOptionUUID: option.uuid } }}
                  />
                </div>
                <div className="flex justify-between items-center mt-5">
                  <div>{t.member.export.attended.title}</div>
                  <CSVExport
                    route={Routes.ATTENDEE_EXPORT}
                    filename={t.member.export.attended.filename(option.name)}
                    payload={{ payload: { attendedEvent: true, eventOptionUUID: option.uuid } }}
                  />
                </div>
                <div className="flex justify-between items-center mt-5">
                  <div>{t.member.export.notAttended.title}</div>
                  <CSVExport
                    route={Routes.ATTENDEE_EXPORT}
                    filename={t.member.export.notAttended.filename(option.name)}
                    payload={{ payload: { attendedEvent: false, eventOptionUUID: option.uuid } }}
                  />
                </div>
                <div className="flex justify-between items-center mt-5">
                  <div>{t.member.export.salesForce.title}</div>
                  <CSVExport
                    route={Routes.SALES_FORCE_EXPORT}
                    filename={t.member.export.salesForce.filename(option.name)}
                    payload={{ payload: { eventOptionUUID: option.uuid } }}
                  />
                </div>
              </div>
            )
          })}
        </div>
      </SmallContainer>
    </div>
  )
}

export default Export
