import logo from '../../../../assets/image/home/hero/logo.svg'
import bg from '../../../../assets/image/home/hero/bg.webp'
import Cursor from '../Cursor/Cursor'

const Hero = () => {
  return (
    <div className="flex items-center justify-center h-screen w-full relative bg-center bg-cover" style={{ backgroundImage: `url(${bg})` }}>
      <img className="w-[90%] md:w-[40%]" src={logo} alt="" />
      <Cursor />
    </div>
  )
}

export default Hero
