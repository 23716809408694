const Tooltip: React.FC<{ text: string; children: React.ReactNode }> = ({ text, children }) => {
  return (
    <div className="relative group">
      <span className="hidden group-hover:block absolute left-1/2 -translate-x-1/2 bottom-full mb-1 bg-white shadow-sm rounded-sm py-1 px-2 whitespace-nowrap">
        {text}
      </span>
      {children}
    </div>
  )
}

export default Tooltip
