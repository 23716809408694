import { functional } from '@think-internet/zeus-frontend-package'
import { downloadFile } from '../../../utility'
import Routes from '../../../redux/routes'

const useCSVExport = (route: Routes, payload: any = {}) => {
  const triggerExport = functional.use(route)

  const start = async (filename: string) => {
    const data = await triggerExport(payload, null, null, null, null, 'text/plain')
    if (!!data) {
      downloadFile(filename, data)
    }
  }

  return start
}

export default useCSVExport
