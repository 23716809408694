import mouse from '../../../../assets/image/mouse.png'

const Cursor = () => {
  return (
    <div className="flex absolute w-full justify-center left-0 bottom-10">
      <img src={mouse} alt="" className="h-10" />
    </div>
  )
}

export default Cursor
