import { useSelector } from 'react-redux'
import dealerFooter from '../../../../assets/image/home/footer/logo.png'
import separatorLine from '../../../../assets/image/separator-with-logo.svg'
import Container from '../../../UI/Container/LargeContainer'
import props from '../../../../redux/props'

const Footer = () => {
  const t = useSelector((s) => s[props.TRANSLATION])

  return (
    <div className="pb-20 md:mb-0 min-h-screen w-full flex items-center">
      <Container>
        <div className="h-[1px] w-[40%] mx-auto bg-white m-5" />
        <div className="text-sm text-center text-white m-5">{t.footer.hint}</div>
        <div className="h-[1px] w-[40%] mx-auto bg-white m-5" />
        <div className="flex my-12">
          <div className="flex-1">
            <img src={dealerFooter} alt="" className="md:h-24" />
          </div>
          <div className="flex-1 text-right">
            <div>
              <a className="text-white text-sm md:text-xl" href="https://guenther-gruppe.de/maserati/datenschutz/" target="_blank" rel="noreferrer">
                {t.footer.dataProtection}
              </a>
            </div>
            <div>
              <a className="text-white text-sm md:text-xl" href="https://guenther-gruppe.de/maserati/impressum/" target="_blank" rel="noreferrer">
                {t.footer.imprint}
              </a>
            </div>
          </div>
        </div>
        <div className="separator">
          <img src={separatorLine} alt="" />
        </div>
        <div className="flex flex-col md:flex-row items-center mt-3">
          <div className="flex-1 text-white text-sm">{t.footer.copyright}</div>
          <div className="flex-1 text-white text-right text-sm">{t.footer.creator}</div>
        </div>
      </Container>
    </div>
  )
}
export default Footer
