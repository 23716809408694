import Info from './Sections/Info/Info'
import Layout from '../UI/Layout/Layout'
import Footer from '../Home/Sections/Footer/Footer'

const Success = () => {
  const sections = [<Info />, <Footer />]

  return (
    <Layout authLevelRequired={false} hideHeader hideFooter>
      <div className="md:snap-y bg-black md:snap-mandatory h-screen w-screen overflow-y-scroll no-scrollbar overflow-x-hidden">
        {sections.map((section, index) => (
          <div key={index} className="md:snap-start w-full h-screen">
            {section}
          </div>
        ))}
      </div>
    </Layout>
  )
}

export default Success
