import { createGlobalStyle } from 'styled-components'

const GlobalStyles = createGlobalStyle`

* {
  padding: 0px;
  margin: 0px;
  font-family: "regular", sans-serif;
  font-size: ${({ theme }) => theme.font.size.md};
  @media (max-width: ${({ theme }) => theme.breakpoint.md}) {
    font-size: ${({ theme }) => theme.font.size.sm};
  }
}

html,
body {
  width: 100%;
  height: 100vh;
  overflow-x: hidden;
  scroll-behavior: smooth;
  background-color: ${({ theme }) => theme.color.bg};
}

input {
  ::placeholder {
    /* Chrome, Firefox, Opera, Safari 10.1+ */
    color: ${({ theme }) => theme.color.white};
    opacity: 1; /* Firefox */
    font-family: 'thin';
  }

  :-ms-input-placeholder {
    /* Internet Explorer 10-11 */
    color: ${({ theme }) => theme.color.white};
  }

  ::-ms-input-placeholder {
    /* Microsoft Edge */
    color: ${({ theme }) => theme.color.white};
  }
}

.tooltip {
  --tooltip-offset: calc(100% + var(--tooltip-tail, 0px))
}

.btn-primary {
  background-color: ${({ theme }) => theme.color.blue.primary};
  border-color: ${({ theme }) => theme.color.blue.primary};
  color: ${({ theme }) => theme.color.white};
  font-size: ${({ theme }) => theme.font.size.md};
  outline: none;
  &:hover {
    background-color: ${({ theme }) => theme.color.blue.secondary};
    border-color: ${({ theme }) => theme.color.blue.secondary};
  }
}

.btn-primary-outline {
  background-color: ${({ theme }) => theme.color.white};
  border-color: ${({ theme }) => theme.color.blue.primary};
  color: ${({ theme }) => theme.color.blue.primary};
  font-size: ${({ theme }) => theme.font.size.md};
  outline: none;
  &:hover {
    background-color: ${({ theme }) => theme.color.blue.primary};
    border-color: ${({ theme }) => theme.color.blue.primary};
    color: ${({ theme }) => theme.color.white};
  }
}

.btn-secondary {
  background-color: ${({ theme }) => theme.color.magenta.secondary};
  border-color: ${({ theme }) => theme.color.magenta.secondary};
  color: ${({ theme }) => theme.color.white};
  font-size: ${({ theme }) => theme.font.size.md};
  outline: none;
  &:hover {
    background-color: ${({ theme }) => theme.color.magenta.primary};
    border-color: ${({ theme }) => theme.color.magenta.primary};
  }
}

.btn-secondary-outline {
  background-color: ${({ theme }) => theme.color.white};
  border-color: ${({ theme }) => theme.color.magenta.secondary};
  color: ${({ theme }) => theme.color.magenta.secondary};
  font-size: ${({ theme }) => theme.font.size.md};
  outline: none;
  &:hover {
    background-color: ${({ theme }) => theme.color.magenta.secondary};
    border-color: ${({ theme }) => theme.color.magenta.secondary};
    color: ${({ theme }) => theme.color.white};
  }
}

.btn-link-primary {
  color: ${({ theme }) => theme.color.font.primary};
  background-color: transparent;
  border: none;
  border-radius: 0;
  width: fit-content;
  font-size: ${({ theme }) => theme.font.size.md};
  outline: none;
  &:hover {
    text-decoration: underline;
    background-color: transparent;
  }
}
`

export default GlobalStyles
