import { useSelector } from 'react-redux'
import props from '../../../redux/props'
import { State } from '../../../types/State'
import SingleList from './SingleList'
import Accordion from '../../UI/Accordion/Accordion'
import LargeContainer from '../../UI/Container/LargeContainer'

const Checklist = () => {
  const state: State = useSelector((s) => s[props.STATE])

  const getItems = () => {
    return state.options.map((option) => {
      return {
        key: option.uuid,
        title: option.name,
        content: <SingleList key={option.uuid} option={option} />,
      }
    })
  }

  if (!state) return null
  return (
    <div className="my-10 flex flex-col gap-20">
      <LargeContainer>
        {state.options.length > 1 ? <Accordion items={getItems()} /> : <SingleList key={state.options[0].uuid} option={state.options[0]} />}
      </LargeContainer>
    </div>
  )
}

export default Checklist
