import { useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import separatorLine from '../../../../assets/image/separator-with-logo.svg'
import props from '../../../../redux/props'
import useToast, { Type } from '../../../hooks/useToast'
import Container from '../../../UI/Container/Container'
import { Input, ReadTheDisclaimerLabel, WithExtraPersonLabel } from './Form.Styled'
import { Attendee } from '../../../../types/Attendee'
import { State } from '../../../../types/State'
import { functional } from '@think-internet/zeus-frontend-package'
import Routes from '../../../../redux/routes'
import styled from 'styled-components'
import folgoreNoBorder from '../../../../assets/image/event_assets/folgore_no_border.png'
import formeleNoBorder from '../../../../assets/image/event_assets/formele_no_border.png'
import { CheckCircleIcon } from '@heroicons/react/24/solid'

type CreateAttendee = Omit<Attendee, 'eventOptionUUID'> & { eventOptionUUIDList: string[] }

const Wrapper = styled.div`
  background: rgb(181, 137, 125);
  background: linear-gradient(to top right, rgba(181, 137, 125, 1) 50%, rgba(10, 10, 10, 1) 50%);
`

const Form = () => {
  const navigate = useNavigate()
  const t = useSelector((s) => s[props.TRANSLATION])
  const state: State = useSelector((s) => s[props.STATE])
  const [data, setData] = useState<CreateAttendee>()
  const toast = useToast()
  const addAttendee = functional.use(Routes.ADD_ATTENDEE)

  useEffect(() => {
    if (!data && state) {
      const init: CreateAttendee = {
        eventUUID: state.eventUUID,
        eventOptionUUIDList: state.options.length === 1 ? [state.options[0].uuid] : [],
        withExtraPerson: false,
        acceptedTerms: false,
      }
      setData(init)
    }
  }, [state, data])

  const updateInput = (key: string) => (e: any) => {
    setData({ ...data, [key]: e.target.value })
  }

  const updateCheckbox = (key: string) => (e: any) => {
    setData({ ...data, [key]: e.target.checked })
  }

  const toggleOptionSelection = (uuid: string) => () => {
    if (data.eventOptionUUIDList.includes(uuid)) {
      setData({ ...data, eventOptionUUIDList: data.eventOptionUUIDList.filter((o) => o !== uuid) })
    } else {
      setData({ ...data, eventOptionUUIDList: [...data.eventOptionUUIDList, uuid] })
    }
  }

  const submit = async (e) => {
    e.preventDefault()
    if (data.eventOptionUUIDList.length > 0) {
      const status = await addAttendee(data)
      if (status) {
        navigate('/success')
      } else {
        toast(Type.ERROR, t.home.sections.form.submitError)
      }
    } else {
      toast(Type.ERROR, t.home.sections.form.eventOptionError)
    }
  }

  const isGeneralRegistrationPossible = () => state.options.some((o) => o.registrationPossible)

  if (!data) return null
  return (
    <Wrapper className="h-full w-full flex relative pb-20 md:pb-5 p-5" id="form">
      <Container>
        <div className="separator">
          <img src={separatorLine} alt="" />
        </div>
        <div className="text-2xl mt-7 mb-5 uppercase text-white text-center">{t.home.sections.form.headline}</div>
        {!isGeneralRegistrationPossible() && (
          <div className="text-center text-white text-lg">
            {t.home.sections.form.limitReached.first}
            <br />
            <br />
            {t.home.sections.form.limitReached.second}
            <br />
            <br />
            {t.home.sections.form.limitReached.third}
          </div>
        )}
        {isGeneralRegistrationPossible() && (
          <form onSubmit={submit}>
            <Input placeholder={t.home.sections.form.firstname} value={data.firstname} onChange={updateInput('firstname')} required />
            <Input placeholder={t.home.sections.form.lastname} value={data.lastname} onChange={updateInput('lastname')} required />
            <Input type="email" placeholder={t.home.sections.form.mail} value={data.mail} onChange={updateInput('mail')} required />
            <WithExtraPersonLabel htmlFor="withExtraPerson">
              <input
                type="checkbox"
                id="withExtraPerson"
                checked={data.withExtraPerson}
                onChange={updateCheckbox('withExtraPerson')}
                style={{ display: 'none' }}
              />
              <span className="checkmark"></span>
              <span className="checkmark-text">{t.home.sections.form.withExtraPerson}</span>
            </WithExtraPersonLabel>
            {data.withExtraPerson && (
              <div className="extra-person-area">
                <Input placeholder={t.home.sections.form.firstname} value={data.firstnameExtra} onChange={updateInput('firstnameExtra')} />
                <Input placeholder={t.home.sections.form.lastname} value={data.lastnameExtra} onChange={updateInput('lastnameExtra')} />
              </div>
            )}
            <ReadTheDisclaimerLabel htmlFor="readTheDisclaimer">
              <input
                type="checkbox"
                id="readTheDisclaimer"
                checked={data.acceptedTerms}
                onChange={updateCheckbox('acceptedTerms')}
                required
                style={{ display: 'none' }}
              />
              <span className="checkmark"></span>
              <span className="checkmark-text text-white">
                {t.home.sections.form.terms.prefix}
                <a href="https://guenther-gruppe.de/maserati/datenschutz/" target="_blank" rel="noreferrer" className="underline">
                  {t.home.sections.form.terms.value}
                </a>{' '}
                {t.home.sections.form.terms.suffix}
              </span>
            </ReadTheDisclaimerLabel>
            <div className="mx-auto md:w-1/2">
              <div className="font-bold text-white uppercase md:text-[24px] mt-10 text-center">{t.home.sections.form.chooseHeadline}</div>
              {state.options.length > 1 && (
                <div className="flex flex-col md:flex-row gap-5 justify-between mt-4">
                  {state.options.map((o) => (
                    <div
                      className={`flex flex-col items-center`}
                      onClick={o.registrationPossible ? toggleOptionSelection(o.uuid) : null}
                      key={o.uuid}
                    >
                      <div
                        className={`w-full md:w-[200px] ${
                          o.registrationPossible ? 'md:hover:scale-105 cursor-pointer' : 'grayscale cursor-not-allowed'
                        } transition-all ${
                          data.eventOptionUUIDList.includes(o.uuid) ? 'border-white' : 'border-pastel'
                        } border-2 rounded-xl aspect-[16/11] bg-cover bg-center relative`}
                        style={{ backgroundImage: `url(${o.identifier === 'folgore' ? folgoreNoBorder : formeleNoBorder})` }}
                      >
                        {data.eventOptionUUIDList.includes(o.uuid) && (
                          <CheckCircleIcon
                            className={`w-10 h-10 absolute right-0 top-0 -translate-y-1/2 translate-x-1/2 fill-white ${
                              data.eventOptionUUIDList.includes(o.uuid) ? 'text-pastel' : 'text-white'
                            }`}
                          />
                        )}
                      </div>
                      <div className="uppercase text-center text-white text-sm font-bold">{o.name}</div>
                    </div>
                  ))}
                </div>
              )}
            </div>
            <div>
              <input
                className="text-pastel mx-auto block w-fit mt-5 bg-white py-2 px-5 rounded-full text-lg uppercase"
                type="submit"
                value={t.home.sections.form.cta}
              />
            </div>
          </form>
        )}
      </Container>
    </Wrapper>
  )
}

export default Form
